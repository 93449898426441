<template>
  <v-form ref="formLavorazioni" v-model="validFormLavorazioni" lazy-validation>
    <v-layout row wrap v-if="lavorazioni.length" v-for="(lavorazione, index) in lavorazioni" :key="index">
      <v-flex xs12 sm9>
        <v-text-field
          v-model="lavorazione.descrizione"
          disabled />
      </v-flex>
      <v-flex xs12 sm3>
        <currency-input
          v-model="lavorazione.prezzo"
          label="Prezzo"
          :name="'prezzo'+lavorazione.tipo_lavorazione_id" />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 lg6>
        <v-btn
          class="ml-0 mt-4"
          color="primary"
          :disabled="!validFormLavorazioni"
          id="salvaLavorazioni"
          @click.native="save">
          Salva
        </v-btn>
      </v-flex>
    </v-layout>
    <v-alert
      outline=""
      color="info"
      value="true"
      v-if="!lavorazioni.length">
      Nessuna lavorazione trovata
    </v-alert>
  </v-form>
</template>

<script>
import { $EventBus } from '@/eventBus'
import currencyInput from '@/components/common/CurrencyInput'

export default {
  data: () => ({
    cliente: null,
    lavorazioni: [],
    validFormLavorazioni: false
  }),
  components: {
    currencyInput
  },
  methods: {
    getLavorazioni () {
      this.$plsqlMethod('cliente', 'get_lavorazioni', { cliente: this.cliente })
        .then(response => {
          this.lavorazioni = response.data
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura lavorazioni' })
        })
    },
    save () {
      this.$plsqlMethod('cliente', 'save_lavorazioni', { cliente: this.cliente, lavorazioni: this.lavorazioni })
        .then(response => {
          $EventBus.$emit('message', {
            type: 'success',
            text: 'Lavorazioni salvate'
          })
          this.getLavorazioni()
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore salvataggio lavorazioni' })
        })
    }
  },
  mounted () {
    this.cliente = this.$route.params.id
    this.getLavorazioni()
  }
}
</script>
